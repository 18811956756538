import React from 'react';
import { Col, Container, Row } from "react-bootstrap";
import ScrollUpButton from "react-scroll-up-button";
import Layout from "../../components/layoutDoc";
import Seo from "../../components/seo";

import WhitepagePoster from '../../images/about/whitepage-poster.png'
import SecureSafeLowerRisk from '../../images/about/secure-safe-lower-risk.png'
import TheUtilityExplained from '../../images/about/the-utility-explained.png'
import PitchDeck from '../../images/about/pitch-deck.png'
import MMbot from '../../images/about/mm-bot.png'

import Whitepaper from '../../images/pdf/HPAY-WhitePaper-Q2.pdf';
import PitchDeckPDF from '../../images/pdf/Pitch-Deck.pdf';
import HPAYMM from '../../images/pdf/HPAY-MM.pdf';
import HedgePayUtilityExplained from '../../images/pdf/HedgePay-Utility-Explained.pdf';
import HedgePaySecurity from '../../images/pdf/HedgePay-Security.pdf';
import { Link } from 'gatsby';

const DATA = [
    {
        image: WhitepagePoster,
        title: 'Whitepaper',
        content: 'Learn about HedgePay’s architecture, ecosystem and HedgeFi mechanism',
        read: '/whitepaper',
        target: '_self',
        download: Whitepaper
    },
    {
        image: SecureSafeLowerRisk,
        title: 'Secure, Safe & Lower Risk ',
        content: 'The security of the holders is paramount in HedgePay strategy',
        read: HedgePaySecurity,
        target: '_blank',
        download: HedgePaySecurity
    },
    {
        image: TheUtilityExplained,
        title: 'The Utility Explained',
        content: 'The explaination of HedgePay ready and upcoming utilities in a detailed manner',
        read: HedgePayUtilityExplained,
        target: '_blank',
        download: HedgePayUtilityExplained
    },
    {
        image: PitchDeck,
        title: 'Pitch Deck',
        content: 'A brief presentation of HedgePay’s overall achievements and an overview of business plan, products and services',
        read: PitchDeckPDF,
        target: '_blank',
        download: PitchDeckPDF
    },
    {
        image: MMbot,
        title: 'Market Maker Bot',
        content: 'Explaination of HedgePay Market Maker Bot on how it worked, guides, command, benefits, comparison and etc.',
        read: HPAYMM,
        target: '_blank',
        download: HPAYMM
    }
]

function About() {
    return (
        <Layout page="About" pageInner="About">
            <ScrollUpButton ContainerClassName="custom-scroll-button" />
            <Seo title="About Us" />
            <Container fluid='md' className="py-md-5 py-4">
                <Row className="justify-content-center text-center mb-lg-5 mb-3">
                    <Col lg={12} className="mb-3">
                        <div className="section-title">
                            <h2>About Us</h2>
                        </div>
                        <p>HedgePay uses rewards generated from each transaction to increase the resources of the HedgeFi Algorithm. Every blockchain transaction that involves transferring tokens is subjected to an 4% fee. 
                            Blockchain Transactions that involve interactions with HedgePay's swap will not incur this fee. The rewards generated will be sent to the HedgeFi Algorithm to increase the rewards of our contributors.</p>
                    </Col>
                </Row>

                {DATA?.map((item, index) => (
                    <Row key={index} className="align-items-center about-list">
                        <Col md={4} className="mb-md-0 mb-3 text-center">
                            <img src={item.image} alt={item.title} className="img-fluid" />
                        </Col>
                        <Col md={8}>
                            <h3>{item.title}</h3>
                            <p>{item.content}</p>
                            {item.target === "_self" ? (
                                <Link to={item.read} target={item.target} className="text-white mr-3 mr-md-4 mb-1 btn btn-outline-primary">Read</Link>
                                ):(
                                <a href={item.read} target={item.target} className="text-white mr-3 mr-md-4 mb-1 btn btn-outline-primary">Read</a>
                            )}
                            <a href={item.download} download="download" className="text-white mb-1 btn btn-primary">Download</a>
                        </Col>
                    </Row>
                ))}
            </Container>
        </Layout>
    );
}

export default About;
