/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React from "react";
import { graphql, StaticQuery } from "gatsby";
import { Container } from "react-bootstrap";
import { ToastContainer } from 'react-toastify';

import '../utils/fontawesome';
// import Footer from "./footer";
import Navbar from "./navBar";
import 'react-toastify/dist/ReactToastify.css';
import Sidebar from "./sidebar";

const Layout = ({ children, pageInfo, page, pageInner, pageShow }) => {
  const pageSet = (e) => {
    if(e){
      pageShow(e)
    }else{
      window.location = "/faq"
    }
  }
  return (<>
    <StaticQuery
      query={graphql`
      query HeaderQuery {
        site {
          siteMetadata {
            title,
            header { 
              mainTitle,
              subtitle
            }
          }
        }
      }
    `}
      render={data => (
        <>
          <div className="main-site-content main-site-content-doc">
            <Container fluid className="px-0 main">
              <Navbar navbar="navbar-active" pageInfo={pageInfo} />
              <Sidebar page={page} pageGet={(e) => pageSet(e)} pageInner={pageInner} />
              <main className="page-doc">{children}</main>
              {/* <Footer /> */}
              <ToastContainer position="bottom-right" autoClose={3000} hideProgressBar closeOnClick pauseOnHover />
            </Container>
          </div>
        </>
      )}
    />
  </>)
};

export default Layout;
