import { Link } from 'gatsby';
import React, { useState } from 'react';
import Logo from '../images/sidebar/logo.png';

function Sidebar({page, pageInner, pageGet}) {
    const [menu, setMenu] = useState(false);
    // const [pageName, setPageName] = useState(page);
    // const [page_Inner, setPage_Inner] = useState(pageInner);

    // const handleMenu = (e) =>{setMenu(true);setPageName(e) }
    // const handleTab = (e) => {setPage_Inner(e); pageGet(e)}

    return ( 
        <>
            <div className="doc-sidebar">
                {/* <Link to="/docs" className={`doc-sidebar-logo ${pageName === 'Introduction' ? 'active' : ''}`}>
                    <img src={Logo} alt="logo" className='img-fluid' />
                </Link> */}
                <ul className='doc-sidebar-list list-unstyled text-center'>
                    <li>
                        {/*  onMouseEnter={() => handleMenu('About')} */}
                        <Link to="/docs" className={`d-flex flex-column align-items-center ${page === 'Introduction' ? 'active' : ''}`}>
                            <img src={Logo} alt="logo" className='img-fluid' />
                        </Link>
                    </li>
                    <li>
                        {/*  onMouseEnter={() => handleMenu('About')} */}
                        <Link to="/docs/about" className={`d-flex flex-column align-items-center ${page === 'About' ? 'active' : ''}`}>
                            <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M23 10.4454C23 14.4341 19.7665 17.6676 15.7778 17.6676C11.7891 17.6676 8.55556 14.4341 8.55556 10.4454C8.55556 6.45664 11.7891 3.22314 15.7778 3.22314C19.7665 3.22314 23 6.45664 23 10.4454Z" fill="url(#paint0_linear_1684_64263)"/>
                                <path d="M17.4444 16.0009C17.4444 19.9896 14.2109 23.2231 10.2222 23.2231C6.2335 23.2231 3 19.9896 3 16.0009C3 12.0122 6.2335 8.7787 10.2222 8.7787C14.2109 8.7787 17.4444 12.0122 17.4444 16.0009Z" fill="url(#paint1_linear_1684_64263)"/>
                                <path d="M8.7134 8.93655C9.20002 8.83312 9.70477 8.7787 10.2222 8.7787C14.2109 8.7787 17.4444 12.0122 17.4444 16.0009C17.4444 16.5184 17.39 17.0231 17.2866 17.5097C16.8 17.6132 16.2952 17.6676 15.7778 17.6676C11.7891 17.6676 8.55556 14.4341 8.55556 10.4454C8.55556 9.92791 8.60997 9.42317 8.7134 8.93655Z" fill="white"/>
                                <defs>
                                    <linearGradient id="paint0_linear_1684_64263" x1="2.26174" y1="-20.3483" x2="38.2291" y2="-12.0149" gradientUnits="userSpaceOnUse">
                                        <stop stop-color="#FFE986" stop-opacity="0.88"/>
                                        <stop offset="0.670219" stop-color="#BC7E20"/>
                                        <stop offset="1" stop-color="#A74D01"/>
                                    </linearGradient>
                                    <linearGradient id="paint1_linear_1684_64263" x1="3" y1="13.2231" x2="23" y2="13.2231" gradientUnits="userSpaceOnUse">
                                        <stop offset="0.11011" stop-color="#D9B95D"/>
                                        <stop offset="0.49458" stop-color="#FFE18F"/>
                                        <stop offset="0.88809" stop-color="#CCA643"/>
                                    </linearGradient>
                                </defs>
                            </svg>
                            <span>About Us</span>
                        </Link>
                    </li>
                    <li>
                        {/*  onMouseEnter={() => handleMenu('Faq')} */}
                        <Link to="/docs/faq" className={`d-flex flex-column align-items-center ${page === 'Faq' ? 'active' : ''}`}>
                            <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M23 13.2231C23 18.746 18.5228 23.2231 13 23.2231C7.47715 23.2231 3 18.746 3 13.2231C3 7.7003 7.47715 3.22314 13 3.22314C18.5228 3.22314 23 7.7003 23 13.2231Z" fill="url(#paint0_linear_1684_64269)"/>
                                <path d="M13.0485 15.5383C12.4336 15.5383 11.924 15.038 11.9906 14.4267C12.0137 14.2147 12.044 14.0281 12.0815 13.8914C12.1825 13.536 12.3289 13.2509 12.5206 13.0363C12.7122 12.8181 12.9457 12.6193 13.221 12.4398C13.4405 12.2991 13.6356 12.1565 13.8064 12.0123C13.9806 11.8645 14.1182 11.7061 14.2193 11.5372C14.3203 11.3683 14.3709 11.1765 14.3709 10.9619C14.3709 10.7542 14.3221 10.5713 14.2245 10.4129C14.127 10.2546 13.9928 10.1331 13.822 10.0487C13.6548 9.96072 13.4684 9.91673 13.2628 9.91673C13.0572 9.91673 12.8638 9.96424 12.6826 10.0592C12.5049 10.1543 12.3585 10.2897 12.2435 10.4657C12.0447 10.7731 11.777 11.0938 11.4109 11.0938H10.8859C10.2153 11.0938 9.63841 10.528 9.86109 9.89556C9.94082 9.66909 10.045 9.46325 10.1737 9.27804C10.5047 8.8065 10.942 8.45812 11.4856 8.23291C12.0292 8.00418 12.6286 7.88981 13.2837 7.88981C14.0015 7.88981 14.6392 8.00242 15.1967 8.22763C15.7577 8.45284 16.1985 8.78539 16.5191 9.22526C16.8397 9.66513 17 10.2035 17 10.8405C17 11.2592 16.9303 11.6287 16.7909 11.9489C16.6515 12.2692 16.4564 12.5524 16.2055 12.7988C15.9581 13.0416 15.6671 13.2633 15.3326 13.4638C15.0713 13.6187 14.8535 13.7805 14.6793 13.9495C14.5085 14.1184 14.3796 14.3119 14.2925 14.5301C14.0805 15.0525 13.7048 15.5383 13.141 15.5383H13.0485ZM13.0903 19.0009C12.7139 19.0009 12.3916 18.8672 12.1233 18.5998C11.8585 18.3323 11.7278 18.0086 11.7313 17.6285C11.7278 17.2555 11.8585 16.9371 12.1233 16.6731C12.3916 16.4057 12.7139 16.272 13.0903 16.272C13.4492 16.272 13.7646 16.4057 14.0364 16.6731C14.3081 16.9371 14.4458 17.2555 14.4493 17.6285C14.4458 17.8819 14.3796 18.1124 14.2507 18.32C14.1252 18.5276 13.9597 18.693 13.7541 18.8162C13.552 18.9393 13.3307 19.0009 13.0903 19.0009Z" fill="url(#paint1_linear_1684_64269)"/>
                                <defs>
                                    <linearGradient id="paint0_linear_1684_64269" x1="3.59144" y1="44.8057" x2="43.4571" y2="3.32243" gradientUnits="userSpaceOnUse">
                                        <stop stop-color="#AC8C38"/>
                                        <stop offset="0.357292" stop-color="#B4994A"/>
                                        <stop offset="0.669792" stop-color="#ECD167"/>
                                        <stop offset="1" stop-color="#786F48"/>
                                    </linearGradient>
                                    <linearGradient id="paint1_linear_1684_64269" x1="13.0016" y1="22.6929" x2="13.0016" y2="3.67026" gradientUnits="userSpaceOnUse">
                                        <stop stop-color="#E0EFF1"/>
                                        <stop offset="0.21" stop-color="#E7F3F4"/>
                                        <stop offset="1" stop-color="white"/>
                                    </linearGradient>
                                </defs>
                            </svg>
                            <span>FAQ</span>
                        </Link>
                    </li>
                    <li>
                        {/*  onMouseEnter={() => handleMenu('Listing Manager')} */}
                        <Link to="/docs/manager" className={`d-flex flex-column align-items-center ${page === 'Listing Manager' ? 'active' : ''}`}>
                            <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M20.1426 7.98485C20.1426 10.6147 18.0107 12.7465 15.3809 12.7465C12.751 12.7465 10.6192 10.6147 10.6192 7.98485C10.6192 5.35503 12.751 3.22314 15.3809 3.22314C18.0107 3.22314 20.1426 5.35503 20.1426 7.98485Z" fill="url(#paint0_linear_1684_64273)"/>
                                <path d="M22.9998 17.8749C22.9998 20.3168 19.5887 21.2935 15.381 21.2935C11.1733 21.2935 7.76231 20.3168 7.76231 17.8749C7.76231 15.6806 11.1733 14.4562 15.381 14.4562C19.5887 14.4562 22.9998 15.433 22.9998 17.8749Z" fill="url(#paint1_linear_1684_64273)"/>
                                <path d="M15.3805 9.91448C15.3805 12.5443 13.2486 14.6762 10.6188 14.6762C7.98898 14.6762 5.85709 12.5443 5.85709 9.91448C5.85709 7.28466 7.98898 5.15277 10.6188 5.15277C13.2486 5.15277 15.3805 7.28466 15.3805 9.91448Z" fill="url(#paint2_linear_1684_64273)"/>
                                <path d="M18.2377 19.8045C18.2377 22.2464 14.8267 23.2231 10.619 23.2231C6.41126 23.2231 3.00024 22.2464 3.00024 19.8045C3.00024 17.6102 6.41126 16.3858 10.619 16.3858C14.8267 16.3858 18.2377 17.3626 18.2377 19.8045Z" fill="url(#paint3_linear_1684_64273)"/>
                                <defs>
                                    <linearGradient id="paint0_linear_1684_64273" x1="22.7892" y1="3.22314" x2="3.00024" y2="3.22314" gradientUnits="userSpaceOnUse">
                                        <stop stop-color="#FFE885"/>
                                        <stop offset="0.3138" stop-color="#E8C15B"/>
                                        <stop offset="1" stop-color="#C08424"/>
                                    </linearGradient>
                                    <linearGradient id="paint1_linear_1684_64273" x1="22.7892" y1="3.22314" x2="3.00024" y2="3.22314" gradientUnits="userSpaceOnUse">
                                        <stop stop-color="#FFE885"/>
                                        <stop offset="0.3138" stop-color="#E8C15B"/>
                                        <stop offset="1" stop-color="#C08424"/>
                                    </linearGradient>
                                    <linearGradient id="paint2_linear_1684_64273" x1="2.26201" y1="-20.3483" x2="38.2286" y2="-12.0153" gradientUnits="userSpaceOnUse">
                                        <stop stop-color="#FFE986" stop-opacity="0.88"/>
                                        <stop offset="0.670219" stop-color="#BC7E20"/>
                                        <stop offset="1" stop-color="#A74D01"/>
                                    </linearGradient>
                                    <linearGradient id="paint3_linear_1684_64273" x1="2.26201" y1="-20.3483" x2="38.2286" y2="-12.0153" gradientUnits="userSpaceOnUse">
                                        <stop stop-color="#FFE986" stop-opacity="0.88"/>
                                        <stop offset="0.670219" stop-color="#BC7E20"/>
                                        <stop offset="1" stop-color="#A74D01"/>
                                    </linearGradient>
                                </defs>
                            </svg>
                            <span>Listing Manager</span>
                        </Link>
                    </li>
                    <li>
                        {/*  onMouseEnter={() => handleMenu('Branding')} */}
                        <Link to="/docs/branding" className={`d-flex flex-column align-items-center ${page === 'Branding' ? 'active' : ''}`}>
                            <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M11.0866 15.3779L10.3087 16.2542C9.70941 16.8524 8.94626 17.2595 8.11567 17.4242C7.28508 17.5888 6.42432 17.5036 5.64216 17.1792C4.86 16.8548 4.19155 16.3059 3.72127 15.6017C3.251 14.8976 3 14.0698 3 13.2231C3 12.3763 3.251 11.5486 3.72127 10.8444C4.19155 10.1402 4.86 9.5913 5.64216 9.26692C6.42432 8.94254 7.28508 8.85729 8.11567 9.02193C8.94626 9.18658 9.70941 9.59375 10.3087 10.192L15.6913 16.2542C16.2906 16.8524 17.0537 17.2595 17.8843 17.4242C18.7149 17.5888 19.5757 17.5036 20.3578 17.1792C21.14 16.8548 21.8084 16.3059 22.2787 15.6017C22.749 14.8976 23 14.0698 23 13.2231C23 12.3763 22.749 11.5486 22.2787 10.8444C21.8084 10.1402 21.14 9.5913 20.3578 9.26692C19.5757 8.94254 18.7149 8.85729 17.8843 9.02193C17.0537 9.18658 16.2906 9.59375 15.6913 10.192L14.9134 11.0682" stroke="url(#paint0_linear_1684_64277)" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                <defs>
                                    <linearGradient id="paint0_linear_1684_64277" x1="3" y1="13.2231" x2="23" y2="13.2231" gradientUnits="userSpaceOnUse">
                                        <stop stop-color="#B07908"/>
                                        <stop offset="0.08" stop-color="#C19325"/>
                                        <stop offset="0.22" stop-color="#DDBD53"/>
                                        <stop offset="0.33" stop-color="#EED66F"/>
                                        <stop offset="0.4" stop-color="#F4E07A"/>
                                        <stop offset="0.44" stop-color="#EFD873"/>
                                        <stop offset="0.68" stop-color="#D5B354"/>
                                        <stop offset="0.88" stop-color="#C69C41"/>
                                        <stop offset="1" stop-color="#C0943A"/>
                                    </linearGradient>
                                </defs>
                            </svg>
                            <span>Branding</span>
                        </Link>
                    </li>
                </ul>
            </div>

            <div className={`doc-sidebar-menu ${menu && 'mob-active'}`}>
                <div className='doc-sidebar-menu-title'>
                    <span>HEDGEPAY</span><span>DOCS</span>

                    <button className='btn p-0 d-lg-none btn-sidebar' onClick={() => setMenu(false)}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-x" viewBox="0 0 16 16">
                        <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                    </svg>
                    </button>
                </div>

                {page === 'Introduction' && (
                    <ul className='list-unstyled doc-sidebar-menu-list'>
                        <li className={pageInner === 'Introduction' ? 'active' : ''}>
                            <Link to="/docs">Introduction</Link>
                        </li>
                        <li className={pageInner === 'Official' ? 'active' : ''}>
                            <Link to="/docs/official-email">Official Email</Link>
                        </li>
                    </ul>
                )}

                {page === 'About' && (
                    <ul className='list-unstyled doc-sidebar-menu-list'>
                        <li className={pageInner === 'About' ? 'active' : ''}>
                            <Link to="/docs/about">About Us</Link>
                        </li>
                    </ul>
                )}

                {/* {pageName === 'Faq' && (
                    <ul className='list-unstyled doc-sidebar-menu-list'>
                        <li className={page_Inner === 'General' && 'active'}>
                            <div onClick={() => handleTab('General')}>General</div>
                        </li>
                        <li className={page_Inner === 'Ecommerce' && 'active'}>
                            <div onClick={() => handleTab('Ecommerce')}>Ecommerce</div>
                        </li>
                        <li className={page_Inner === 'Rewards' && 'active'}>
                            <div onClick={() => handleTab('Rewards')}>Rewards</div>
                        </li>
                        <li className={page_Inner === 'HPAD' && 'active'}>
                            <div onClick={() => handleTab('HPAD')}>HPAD</div>
                        </li>
                        <li className={page_Inner === 'AuditKYC' && 'active'}>
                            <div onClick={() => handleTab('AuditKYC')}>Audit & KYC</div>
                        </li>
                        <li className={page_Inner === 'HedgeTalk' && 'active'}>
                            <div onClick={() => handleTab('HedgeTalk')}>HedgeTalk: AMA</div>
                        </li>
                        <li className={page_Inner === 'MarketMaker' && 'active'}>
                            <div onClick={() => handleTab('MarketMaker')}>Market Maker</div>
                        </li>
                        <li className={page_Inner === 'Partnership' && 'active'}>
                            <div onClick={() => handleTab('Partnership')}>Partnership</div>
                        </li>
                    </ul>
                )} */}

                {page === 'Faq' && (
                    <ul className='list-unstyled doc-sidebar-menu-list'>
                        <li className={pageInner === 'General' && 'active'}>
                            <Link to="/docs/faq/general">General</Link>
                        </li>
                        <li className={pageInner === 'Ecommerce' && 'active'}>
                            {/* <div onClick={() => handleTab('Ecommerce')}>Ecommerce</div> */}
                            <Link to="/docs/faq/products">Ecommerce</Link>
                        </li>
                        <li className={pageInner === 'Rewards' && 'active'}>
                            {/* <div onClick={() => handleTab('Rewards')}>Rewards</div> */}
                            <Link to="/docs/faq/rewards">Rewards</Link>
                        </li>
                        <li className={pageInner === 'HPAD' && 'active'}>
                            {/* <div onClick={() => handleTab('HPAD')}>HPAD</div> */}
                            <Link to="/docs/faq/hpad">HPAD</Link>
                        </li>
                        <li className={pageInner === 'AuditKYC' && 'active'}>
                            {/* <div onClick={() => handleTab('AuditKYC')}>Audit & KYC</div> */}
                            <Link to="/docs/faq/leaderboard">Audit & KYC</Link>
                        </li>
                        <li className={pageInner === 'HedgeTalk' && 'active'}>
                            {/* <div onClick={() => handleTab('HedgeTalk')}>HedgeTalk: AMA</div> */}
                            <Link to="/docs/faq/hedgetalk">HedgeTalk: AMA</Link>
                        </li>
                        <li className={pageInner === 'MarketMaker' && 'active'}>
                            {/* <div onClick={() => handleTab('MarketMaker')}>Market Maker</div> */}
                            <Link to="/docs/faq/mm">Market Maker</Link>
                        </li>
                        <li className={pageInner === 'Partnership' && 'active'}>
                            {/* <div onClick={() => handleTab('Partnership')}>Partnership</div> */}
                            <Link to="/docs/faq/partnership">Partnership</Link>
                        </li>
                    </ul>
                )}

                {page === 'Listing Manager' && (
                    <ul className='list-unstyled doc-sidebar-menu-list'>
                        <li className={pageInner === 'Listing Manager' && 'active'}>
                            <Link to="/listing-manager">Listing Manager</Link>
                        </li>
                    </ul>
                )}

                {page === 'Branding' && (
                    <ul className='list-unstyled doc-sidebar-menu-list'>
                        <li className={pageInner === 'Branding' && 'active'}>
                            <Link to="/branding">HedgePay</Link>
                        </li>
                    </ul>
                )}
            </div>
        </>
     );
}

export default Sidebar;